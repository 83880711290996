import React, { useState, useContext, useEffect } from "react";
import "./splash-screen.css";
import { TopicContext } from "./TopicContext";
import http from "../lib/http";

export default function SplashScreen() {
  let interactiveId = 4;
  const [splashText, setSplashText] = useState();
  const [splashTitle, setSplashTitle] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/items/${interactiveId}`);
      setSplashText(data.item.splashText);
      setSplashTitle(data.item.title);
    }
    fetchData();
  });
  //eslint-disable-next-line
  const { showSplash, setShowSplash } = useContext(TopicContext);
  const handleClick = () => {
    document.getElementById("splash-screen").classList.add("fade-out");
  };
  return showSplash === true ? (
    <div id="splash-screen" onClick={handleClick}>
      <div className="splash-text-container">
        <div className="splash-title">
          <h1>{splashTitle}</h1>
        </div>
        <hr />
        <div className="splash-text">
          <h3>{splashText}</h3>
        </div>
        <div className="splash-start-button">Start</div>
      </div>
      <div className="splash-image-container">
        <img
          className="splash-image"
          alt="Puppy and Kitten"
          src="https://storage.googleapis.com/purina-virtual-experience/design/hero-images/04-brain-hero.png"
        />
      </div>
    </div>
  ) : null;
}
